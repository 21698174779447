import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Checkbox, Input } from '@lynx/client-core/src/components'
import { ErrorMessage, Form, Formik } from 'formik'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ApplicationState } from 'store'
import { OrganisationNextCloudDomainModalContextType, showModal } from 'store/modal'
import { createOrganisation } from 'store/organisations'
import css from './OrganisationDetails.module.scss'
import { organisationSchema } from 'pages/Organisations/validationSchemas'
import { CountryDropdown } from 'pages/OrganisationEdit/components/CountryDropdown/CountryDropdown'

export interface OrganisationDetailsProps {
	navigate: (section: string) => void
	context?: OrganisationDetailsContext
}

export const OrganisationDetails = ({ navigate: navigateSection, context }: OrganisationDetailsProps): React.ReactElement => {
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()

	const nextCloudOrganisationInitialData = useSelector((state: ApplicationState) =>
		state?.modal?.context?.type === OrganisationNextCloudDomainModalContextType ? state.modal.context : null
	)
	const [countries, setCountries] = useState<{ id: string; name: string }[]>([])

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const countriesResponse = await APIRequest.Pages.getCountries()
			setCountries(countriesResponse.data)
		}
		mount()
	}, [])

	const handleFormSubmitted = async ({
		addr,
		country,
		orgName,
		postCode: postcode,
		termsAgree
	}: {
		addr: string
		country: string
		orgName: string
		postCode: string
		termsAgree: boolean
	}): Promise<void> => {
		const issuer = context?.saml?.issuer || ''
		const certificate = context?.saml?.certificate || ''
		const entrypoint = context?.saml?.entrypoint || ''
		const samlDomains: string[] = context?.saml?.domains || []

		let oauthClientIdStr = ''
		let oauthClientSecretStr = ''
		let oauthAuthorizationUrlStr = ''
		let oauthTokenUrlStr = ''
		let oauthUserUrlStr = ''
		let oauthDomainsStr: string[] = []

		const isUCS = context?.isUCS
		const isIserv = Boolean(context?.isIserv)
		const isEntraId = Boolean(context?.isEntraId)

		const entraIdTenantId = context?.entraId?.tenantId || ''
		const entraIdDomainsStr = context?.entraId?.domains || []

		if (context?.oauth) {
			const { clientId, clientSecret, authorizationUrl, tokenUrl, userUrl, domains } = context.oauth
			oauthClientIdStr = clientId
			oauthClientSecretStr = clientSecret
			oauthAuthorizationUrlStr = authorizationUrl
			oauthTokenUrlStr = tokenUrl
			oauthUserUrlStr = userUrl
			oauthDomainsStr = domains || []
		}

		const organisations = await dispatchThunk(
			createOrganisation({
				name: orgName,
				address: addr,
				postcode,
				country: String(country),
				termsAgree,
				nextCloudOrganisationInitialData,
				entrypoint,
				issuer,
				certificate,
				samlDomains,
				isUCS: Boolean(isUCS),
				isIserv,
				oauthClientIdStr,
				oauthClientSecretStr,
				oauthAuthorizationUrlStr,
				oauthTokenUrlStr,
				oauthUserUrlStr,
				oauthDomainsStr,
				isEntraId,
				entraIdTenantId,
				entraIdDomainsStr
			})
		)

		dispatch(showModal({ name: 'OrganisationCreatedModal' }))
	}

	return (
		<Formik
			initialValues={{
				addr: nextCloudOrganisationInitialData?.address || '',
				country: 'United Kingdom',
				orgName: nextCloudOrganisationInitialData?.organisationName || '',
				postCode: '',
				termsAgree: false
			}}
			validationSchema={organisationSchema}
			onSubmit={(values, actions): void => {
				handleFormSubmitted(values)
				actions.setSubmitting(false)
			}}
		>
			{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
				<Form className={css['container']}>
					<div>
						<label htmlFor="orgName">{i18next.t('components.modals.organisationCreateModal.organisationName')}</label>
						<Input
							autoFocus
							value={values.orgName}
							type="search"
							name="orgName"
							onChange={handleChange}
							inError={(errors.orgName && touched.orgName) || false}
						/>
						<ErrorMessage name="orgName" component="span" />
					</div>
					<div>
						<label htmlFor="addr">{i18next.t('components.modals.organisationCreateModal.address')}</label>
						<Input value={values.addr} type="search" name="addr" onChange={handleChange} inError={(errors.addr && touched.addr) || false} />
						<ErrorMessage name="addr" component="span" />
					</div>
					<div>
						<label htmlFor="postCode">{i18next.t('components.modals.organisationCreateModal.postCode')}</label>
						<Input
							type="search"
							name="postCode"
							value={values.postCode}
							onChange={handleChange}
							inError={(errors.postCode && touched.postCode) || false}
						/>
						<ErrorMessage name="postCode" component="span" />
					</div>
					<div>
						<label htmlFor="country">{i18next.t('components.modals.organisationCreateModal.country')}</label>
						<CountryDropdown handleCountryChanged={handleChange} countryName={'U'} countries={countries} />
					</div>
					<div className={css['agreement']}>
						<div className={css['terms-link']} onClick={(): void => navigate('/terms')}>
							{i18next.t('components.modals.organisationCreateModal.agreeToTerms')}
						</div>
						<Checkbox checked={values.termsAgree} value={String(values.termsAgree)} name="termsAgree" onChange={handleChange} />
					</div>
					<ErrorMessage name="termsAgree" component="span" />
					<div className={css['buttons']}>
						<BasicButton
							onClick={(): void => {
								navigateSection('OrganisationTypeSelector')
							}}
						>
							{i18next.t('pages.organisations.createModal.back')}
						</BasicButton>
						<BasicButton variant={isValid ? 'blue' : 'transparent'} disabled={isSubmitting || !dirty} type="submit">
							{i18next.t('components.modals.organisationCreateModal.create')}
						</BasicButton>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export interface OrganisationDetailsContext {
	saml: {
		issuer: string
		certificate: string
		entrypoint: string
		domains?: string[]
	}
	oauth: {
		authorizationUrl: string
		tokenUrl: string
		userUrl: string
		clientId: string
		clientSecret: string
		domains?: string[]
	}
	entraId: {
		tenantId: string
		domains?: string[]
	}
	isEntraId: boolean
	isIserv: boolean
	isUCS: boolean
}
