import { Logger } from '../../modules'
import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'

export class Pages extends BaseCall {
	public getLicense = () => {
		return this.client.get('/json/license').then((response) => {
			return response.data
		})
	}

	public getVersion = async (versionUrl: string): Promise<string> => {
		try {
			const version = await this.client.post('/downloads/detail', {
				type: 'get',
				url: versionUrl
			})
			if (version.data.status) {
				return version.data.data
			}
			return 'Could not get Version Info'
		} catch {
			return 'Could not get Version Info'
		}
	}

	public getCountryFix = () => {
		return this.client.get('/view/elmstone/country-fix')
	}

	public getCountries = (): Promise<{ data: { id: string; name: string }[] }> => {
		try {
			return this.client.get('/countries')
		} catch (error) {
			Logger.error(error)
			throw error
		}
	}

	public getFAQ = () => {
		return this.client
			.get('/view/faq')
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				console.error(err)
				throw err
			})
	}

	public postMailingList = (date: string) => {
		return this.client
			.post('/api-v2/mailing-list', {
				createdDate: date
			})
			.then((response) => {
				return response.data
			})
	}

	public getMailingList = () => {
		return this.client.get('/view/mailing-list').then((response) => {
			return response.data
		})
	}

	public getTranslations = () => {
		return this.client.get('/view/translation').then((response) => {
			return response.data
		})
	}

	public getNetworkTopics = () => {
		return this.client.get('/view/network-topics').then((response) => {
			return response.data.topics
		})
	}

	public getRecentUsers = (params: any) => {
		return this.client
			.get('/api-v2/recentUserData', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				params: params
			})
			.then((response) => {
				return response.data
			})
	}

	public getSaharaRecentUsers = () => {
		return this.client.get('/view/elmstone/sahara-recent-users').then((response) => {
			return response.data
		})
	}

	// General Get
	public get = (url: string): Promise<any> => {
		return this.client.get(url, { withCredentials: true })
	}

	public postFeedback(email: string, message: string, base64Canvas: string): Promise<any> {
		const bodyFormData = new FormData()
		bodyFormData.set('email', email)
		bodyFormData.set('message', message)
		bodyFormData.set('screenshot', base64Canvas)

		return this.client
			.post('/api-v2/feedback/website/submit', bodyFormData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				withCredentials: true
			})
			.then((response) => {
				return response.data
			})
	}

	public getManifestFile = (deploy = 'prod') => {
		return this.client
			.get(`/launcher/manifest/${deploy}`)
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				console.error(err)
			})
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
