import { PersonAddIcon, ProBadgeIcon, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { OrganisationGroupsInterface } from '@lynx/core/src/interfaces/Organisation'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { DeleteGroupModalContextType } from 'pages/Organisations/modals/DeleteGroupModal/DeleteGroupModalContextType'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { EditGroupEntryOverlay } from './EditGroupEntryOverlay'
import css from './GroupsTable.module.scss'
import { RenameGroupModalContextType } from 'pages/Organisations/modals/RenameGroupModal/RenameGroupModalContextType'
import { InvitePeopleModalContextType, showModal } from 'store/modal'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

interface Props {
	upgradeGroupPro: (groupId?: number) => Promise<void>
	canAddProLicenses: (licensesCount: number) => boolean
}

export const GroupsTable = ({ upgradeGroupPro, canAddProLicenses }: Props): React.ReactElement | null => {
	const dispatch = useDispatch()
	const organisation = useCurrentOrganisation()
	const { t } = useTranslation()
	const { profile } = useSelector((state: ApplicationState) => state)
	if (!organisation) return null

	const handleDeleteGroup = (group: OrganisationGroupsInterface): void => {
		dispatch(showModal({ name: 'DeleteGroupModal', context: { type: DeleteGroupModalContextType, ...group, organisationId: organisation.id } }))
	}

	const handleRenameGroup = (group: OrganisationGroupsInterface): void => {
		dispatch(showModal({ name: 'RenameGroupModal', context: { type: RenameGroupModalContextType, ...group, organisationId: organisation.id } }))
	}

	const handleCreateGroupClicked = (): void => {
		dispatch(showModal({ name: 'CreateGroupModal' }))
	}

	const inviteToGroup = (group: OrganisationGroupsInterface): void => {
		dispatch(showModal({ name: 'InvitePeopleModal', context: { type: InvitePeopleModalContextType, groupId: group.groupId } }))
	}

	const columns: TableHeaders = [
		{
			valueKey: 'groupName',
			label: 'NAME',
			cellAdditionalStyles: { alignItems: 'center' },
			customBodyRender: (item: OrganisationGroupsInterface): React.ReactElement => <div>{item.groupName}</div>
		},
		{
			valueKey: 'userCount',
			label: 'USER COUNT',
			cellAdditionalStyles: { alignItems: 'center' },
			customBodyRender: (item: OrganisationGroupsInterface): React.ReactElement => <div>{(item.users || []).length}</div>
		},
		{
			valueKey: '__edit',
			label: '',
			customBodyRender: (item: OrganisationGroupsInterface): React.ReactElement => {
				const groupUserIds = (item.users || []).map((user) => user.userId)
				const groupUsers = organisation.users.filter((user) => groupUserIds.includes(user.userId))
				const usersWithoutProCount = groupUsers.filter((user) => !user.isAssignedPro).length
				const canAddProToGroup = groupUsers.length && usersWithoutProCount && canAddProLicenses(usersWithoutProCount)
				const isGroupAdmin = (item.users || []).some((user) => user.userId === profile.userId && user.isAdmin)
				if (!(organisation.isOwner || isGroupAdmin)) return <div />
				return (
					<div className={css['optionsContainer']}>
						<EditGroupEntryOverlay>
							<ul>
								<li>
									<Link className={css.link} to={`/organisations/${organisation.id}/groups/${item.groupId}`}>
										{t('pages.organisations.manageGroup')}
									</Link>
								</li>
								<li onClick={(): void => inviteToGroup(item)}>{t('pages.organisations.inviteUser')}</li>
								{organisation.isOwner && <li onClick={(): void => handleDeleteGroup(item)}>{t('pages.organisations.deleteGroup')}</li>}
								<li onClick={(): void => handleRenameGroup(item)}>{t('pages.organisations.renameGroup')}</li>
								{organisation.isOwner && (
									<li
										className={canAddProToGroup ? '' : css.disabled}
										onClick={(): Promise<void> | null => (canAddProToGroup ? upgradeGroupPro(item.groupId) : null)}
									>
										<ProBadgeIcon /> {t('pages.organisations.upgradeUsersPro')}
									</li>
								)}
							</ul>
						</EditGroupEntryOverlay>
					</div>
				)
			}
		}
	]

	return (
		<div>
			<Table
				headers={columns}
				items={organisation.groups}
				showPagination={true}
				toolBarItems={[
					{
						component: (
							<div onClick={handleCreateGroupClicked}>
								<PersonAddIcon />
								<div>{t('pages.organisations.createGroup')}</div>
							</div>
						)
					}
				]}
			/>
		</div>
	)
}
