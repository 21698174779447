import {
	AppsIcon,
	ArrowBackIcon,
	BasicButton,
	Dropdown,
	HomeIcon,
	RefreshIcon,
	SortAzIcon,
	SortDateAscendingIcon,
	SortDateDescendingIcon,
	SortIcon,
	SortZaIcon,
	ViewHeadlineIcon
} from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { homeTitlesMapping } from 'data/driveTitleMapping'
import envVars from 'data/env-vars'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import { Breadcrumbs } from 'pages/Drives/Breadcrumbs'
import { DriveFilters } from 'pages/Drives/DriveList/DriveList.types'
import { Pills } from 'pages/Drives/Pills'
import { ControlsProps } from 'pages/Drives/interfaces'
import QueryString from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { changeDriveStyle, refreshDrivePath } from 'store/drive'
import { HiddenDrivesModal } from 'pages/Drives/HiddenDrivesModal/HiddenDrivesModal'
import css from './Controls.module.scss'
import { DriveDropdown } from './DriveDropdown'
import { HomeDropdown } from './HomeDropdown'

export const Controls = ({
	provider,
	selectedFilter,
	onHomeClicked,
	onBackClicked,
	onCreateClicked,
	onUploadClicked,
	onCreateFolderClicked,
	onFilterClicked,
	setSortDirection
}: ControlsProps): React.ReactElement => {
	const { drive: driveState } = useSelector((state: ApplicationState) => state)
	const { writeable, currentPath, paths, favourites } = driveState
	const dispatchThunk = useThunkDispatch()
	const { width } = useWindowDimensions()
	const { t } = useTranslation()

	const countFavorites = favourites.length

	const handleDriveStyleClicked = async (): Promise<void> => {
		const nextStyle = driveState.style === 'grid' ? 'table' : 'grid'
		try {
			await dispatchThunk(changeDriveStyle(nextStyle))
		} catch (e) {
			Logger.error(e)
		}
	}

	const refreshItem = async (): Promise<void> => {
		try {
			await dispatchThunk(refreshDrivePath())
		} catch (e) {
			Logger.error(e)
		}
	}

	const isHomeRoute = currentPath?.name === 'Home'

	const getTitle = (): string => {
		const query = QueryString.parse(location.search.substring(1)) as { driveId?: string }
		const selectedDrive = driveState.homeDrives?.children.find(({ id }) => query.driveId === id)
		const providerName = homeTitlesMapping[provider || 'home'].name
		if (selectedDrive) return `${providerName} - ${selectedDrive?.name}`
		return providerName
	}

	const getButtonSize = (): 'xs' | 'sm' | 'md' => {
		if (width < 768) return 'xs'
		if (width < 1024) return 'sm'
		return 'md'
	}

	const buttonSize = getButtonSize()

	const containerClasses = [css.container]
	driveState.writeable && containerClasses.push('hero-background')

	return (
		<div className={containerClasses.join(' ')}>
			<div className={css.titleContainer}>
				<span className={css.title}>{getTitle()}</span>
			</div>

			<div className={css.buttonsContainer}>
				<div className={css.buttonsContainerLeft}>
					<BasicButton aria-label="drive-back" size={buttonSize} icon={ArrowBackIcon} onClick={onBackClicked} disabled={paths.length <= 1} />
					{onHomeClicked && <BasicButton aria-label="drive-home" size={buttonSize} icon={HomeIcon} onClick={onHomeClicked} disabled={isHomeRoute} />}
					<BasicButton aria-label="drive-refresh" size={buttonSize} icon={RefreshIcon} onClick={refreshItem} disabled={isHomeRoute} />
					{isHomeRoute && <HomeDropdown buttonSize={buttonSize} />}

					{!isHomeRoute && (
						<DriveDropdown
							buttonSize={buttonSize}
							onCreateClicked={onCreateClicked}
							onCreateFolderClicked={onCreateFolderClicked}
							onUploadClicked={onUploadClicked}
						/>
					)}
				</div>
				<div className={css.buttonsContainerRight}>
					<Dropdown
						title={
							<BasicButton size={buttonSize} disabled={isHomeRoute} icon={SortIcon}>
								{t('pages.drive.sortButton')}
							</BasicButton>
						}
						disabled={isHomeRoute}
					>
						<li className={css.newListItem} onClick={(): void => setSortDirection('asc')}>
							<SortAzIcon className={css.sortIcon} /> <div>{t('pages.drive.controls.sortList.asc')}</div>
						</li>
						<li className={css.newListItem} onClick={(): void => setSortDirection('desc')}>
							<SortZaIcon className={css.sortIcon} /> <div>{t('pages.drive.controls.sortList.desc')}</div>
						</li>
						<li className={css.newListItem} onClick={(): void => setSortDirection('newest')}>
							<SortDateAscendingIcon className={css.sortIcon} /> <div>{t('pages.drive.controls.sortList.newest')}</div>
						</li>
						<li className={css.newListItem} onClick={(): void => setSortDirection('oldest')}>
							<SortDateDescendingIcon className={css.sortIcon} /> <div>{t('pages.drive.controls.sortList.oldest')}</div>
						</li>
						<li className={css.newListItem} onClick={(): void => setSortDirection(null)}>
							{t('pages.drive.controls.sortList.default')}
						</li>
					</Dropdown>

					<BasicButton
						aria-label="view"
						size={buttonSize}
						onClick={(): void => {
							driveState.style === 'grid' ? handleDriveStyleClicked() : handleDriveStyleClicked()
						}}
						icon={driveState.style === 'grid' ? ViewHeadlineIcon : AppsIcon}
					/>
				</div>
			</div>
			<div className={css['breadcrumb']}>
				<div className={css['breadcrumb-row']}>
					<Breadcrumbs />
					<HiddenDrivesModal />
				</div>
				{isHomeRoute && envVars.showFavourites && (
					<Pills
						selected={selectedFilter}
						pills={[
							{
								label: t('pages.drive.controls.filters.all'),
								value: 'all'
							},
							{
								label: t('pages.drive.controls.filters.favourites'),
								value: 'favourites',
								badge: countFavorites ? `${countFavorites}` : ''
							}
						]}
						onSelect={(value): Promise<void> => onFilterClicked(value as DriveFilters)}
					/>
				)}
			</div>
		</div>
	)
}
