import { useSelector } from 'react-redux'
import css from './HiddenDrivesModal.module.scss'
import { ApplicationState } from 'store'
import { BasicButton, QuestionMarkIcon } from '@lynx/client-core/src/components'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { useState } from 'react'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { useTranslation } from 'react-i18next'

export const HiddenDrivesModal = (): React.ReactElement => {
	const { drive: driveState } = useSelector((state: ApplicationState) => state)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { t } = useTranslation()
	const hiddenDrivesCount = driveState.drives.hidden.length

	const hiddenDrivesEl = hiddenDrivesCount ? (
		<div className={css.hiddenDrives} onClick={(): void => setIsModalOpen(true)}>
			{hiddenDrivesCount} {t('components.modals.hiddenDrives')} <QuestionMarkIcon />
		</div>
	) : (
		''
	)

	return (
		<div className={css.container}>
			{hiddenDrivesEl}
			{isModalOpen && (
				<ModalBase name="" uncontrolled onHidden={(): void => setIsModalOpen(false)}>
					<ModalDefaultLayout
						title={t('components.modals.hiddenDrives')}
						body={
							<>
								<p>
									{hiddenDrivesCount} {t('components.modals.drivesAreHidden')}.
								</p>
								<div>{t('components.modals.gotoViewThem')}</div>
							</>
						}
						footer={
							<>
								<BasicButton className={css.cancelBtn} onClick={(): void => setIsModalOpen(false)}>
									{t('components.modals.cancel')}
								</BasicButton>
								<BasicButton to={'/profile'} variant="blue">
									{t('components.modals.myAccount')}
								</BasicButton>
							</>
						}
					/>
				</ModalBase>
			)}
		</div>
	)
}
