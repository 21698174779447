import { APIRequest } from '@lynx/client-core/src/api'
import { ArrowBackIcon, BasicButton, CloudIcon, Document, FileIcon, Input, KeyIcon, Modal, Tabs } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { ErrorMessage, Form, Formik } from 'formik'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import { DriveUsage } from 'pages/Organisations/components/ActiveOrganisation/components'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideModal, showModal } from 'store/modal'
import { createOrganisationDrive, loadOrganisations, organisationAvatarUpdated, updateOrganisation } from 'store/organisations'
import css from './OrganisationEdit.module.scss'
import { CountryDropdown } from './components/CountryDropdown/CountryDropdown'
import { organisationSchema } from 'pages/Organisations/validationSchemas'
import { OrganisationDrivesAccess } from './components/OrganisationDrivesAccess/OrganisationDrivesAccess'
import { OrganisationPermissionsManagement } from './components/OrganisationPermissionsManagement/OrganisationPermissionsManagement'
import { OrganisationAvatarEdit } from './OrganisationAvatarEdit'
import { Link, useParams } from 'react-router-dom'
import { OrganisationDeleteDriveModal, OrganisationDeleteModal } from 'pages/Organisations/modals'

export const OrganisationEdit = (): React.ReactElement => {
	const [countries, setCountries] = useState<{ id: string; name: string }[]>([])
	const dispatchThunk = useThunkDispatch()
	const organisation = useCurrentOrganisation()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation()
	const [uploadedFileAvatar, setUploadedFileAvatar] = useState<File | null>(null)
	const [selectedTab, setSelectedTab] = useState<string>('details')
	const { organisationId } = useParams()

	useEffect(() => {
		const mount = async (): Promise<void> => {
			await handleVisible()
			if (!organisation) await dispatchThunk(loadOrganisations(Number(organisationId)))
		}
		mount()
	}, [organisation])

	const handleVisible = async (): Promise<void> => {
		try {
			const countriesResponse = await APIRequest.Pages.getCountries()
			setCountries(countriesResponse.data)
		} catch (err) {
			// do nothing
		}
	}
	if (!organisation) return <div />

	const handleEnableDriveClicked = async (): Promise<void> => {
		await dispatchThunk(createOrganisationDrive(organisation.id))
	}

	const handleDeleteOrganisationClicked = async (): Promise<void> => {
		dispatch(showModal({ name: 'OrganisationDeleteModal' }))
	}

	const handleDeleteDriveClicked = async (): Promise<void> => {
		dispatch(showModal({ name: 'OrganisationDeleteDriveModal' }))
	}

	const uploadOrganisationAvatar = async (): Promise<void> => {
		if (!uploadedFileAvatar) return
		await APIRequest.Organisations.uploadOrganisationAvatar(id, uploadedFileAvatar)
		dispatch(organisationAvatarUpdated(id))
	}

	const handleFormSubmitted = async ({
		addr,
		country,
		orgName,
		postCode: postcode
	}: {
		addr: string
		country: string
		orgName: string
		postCode: string
	}): Promise<void> => {
		try {
			setIsLoading(true)
			if (uploadedFileAvatar) await uploadOrganisationAvatar()
			await dispatchThunk(updateOrganisation(organisation.id, orgName, addr, postcode, `${country}`))
			dispatch(hideModal())
		} catch (err) {
			Logger.error(err)
		} finally {
			setIsLoading(false)
		}
	}

	const { organisationName, address, postCode, id, avatarUrl, isPro, driveId } = organisation

	const tabs = [
		{
			label: (
				<div className={css.tabOption}>
					<FileIcon />
					{i18next.t('components.modals.organisationUserEditModal.tabDetails')}
				</div>
			),
			key: 'details'
		},
		{
			label: (
				<div className={css.tabOption}>
					<CloudIcon />
					{i18next.t('components.modals.organisationUserEditModal.tabDrives')}
				</div>
			),
			key: 'drives'
		},
		{
			label: (
				<div className={css.tabOption}>
					<KeyIcon /> {i18next.t('components.modals.organisationUserEditModal.tabAccess')}
				</div>
			),
			key: 'access'
		}
	]

	return (
		<Document title={organisationName} auth>
			<div className={css['container']}>
				<Link to={`/organisations/${id}`} className={css.backContainer}>
					<ArrowBackIcon /> {i18next.t('components.modals.organisationUserEditModal.back')}
				</Link>
				<h1 className={css.title}>{i18next.t('pages.organisation.editOrganisation')}</h1>
				<Tabs
					selectedTab={selectedTab}
					setSelectedTab={(tabKey: string): void => {
						setSelectedTab(tabKey)
					}}
					tabs={tabs}
				/>
				{selectedTab === 'details' && (
					<Formik
						initialValues={{ addr: address, country: 'United Kingdom', orgName: organisationName, postCode }}
						validationSchema={organisationSchema}
						onSubmit={(values, actions): void => {
							handleFormSubmitted(values)
							actions.setSubmitting(false)
						}}
					>
						{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
							<Form className={css['form']}>
								<OrganisationAvatarEdit
									isDriveEnabled={!!organisation.driveId}
									uploadedFileAvatar={uploadedFileAvatar}
									setUploadedFileAvatar={setUploadedFileAvatar}
									avatarUrl={avatarUrl || ''}
								/>
								<div>
									<div className={css['name-label']}>
										<label htmlFor="orgName">{i18next.t('components.modals.organisationCreateModal.organisationName')}</label>
										<div>ID: {id}</div>
									</div>
									<Input
										autoFocus
										value={values.orgName}
										type="search"
										name="orgName"
										onChange={handleChange}
										inError={(errors.orgName && touched.orgName) || false}
									/>
									<ErrorMessage name="orgName" component="span" />
								</div>
								<div>
									<label htmlFor="addr">{i18next.t('components.modals.organisationCreateModal.address')}</label>
									<Input
										value={values.addr}
										type="search"
										name="addr"
										onChange={handleChange}
										inError={(errors.addr && touched.addr) || false}
									/>
									<ErrorMessage name="addr" component="span" />
								</div>
								<div>
									<label htmlFor="postCode">{i18next.t('components.modals.organisationCreateModal.postCode')}</label>
									<Input
										type="search"
										name="postCode"
										value={values.postCode}
										onChange={handleChange}
										inError={(errors.postCode && touched.postCode) || false}
									/>
									<ErrorMessage name="postCode" component="span" />
								</div>
								<div>
									<label htmlFor="country">{i18next.t('components.modals.organisationCreateModal.country')}</label>
									<CountryDropdown handleCountryChanged={handleChange} countryName={'U'} countries={countries} />
								</div>
								<div className={css['buttons']}>
									<BasicButton
										onClick={(): void => {
											dispatch(hideModal())
										}}
									>
										{i18next.t('pages.organisations.createModal.back')}
									</BasicButton>
									<BasicButton
										variant={isValid ? 'blue' : 'transparent'}
										disabled={isSubmitting || !dirty}
										type="submit"
										isLoading={isLoading}
									>
										{i18next.t('pages.organisations.save')}
									</BasicButton>
								</div>
							</Form>
						)}
					</Formik>
				)}
				{selectedTab === 'drives' && (
					<div>
						<h2 className={css.organisationStorageLabel}>{t('pages.organisations.organisationDriveStorage')}</h2>
						{!driveId && (
							<BasicButton className={css['button-small']} variant="grey" onClick={handleEnableDriveClicked}>
								{t('pages.organisations.enableDrive')}
							</BasicButton>
						)}
						{driveId && (
							<div className={css['drive']}>
								<div className={css['drive__limit']}>
									<DriveUsage />
									{!isPro && driveId && (
										<BasicButton className={css.upgradeBtn} variant="blue" to={`/contact-us?organisationId=${organisation.id}`}>
											{t('pages.organisation.moreStorage')}
										</BasicButton>
									)}
								</div>
								<BasicButton variant="danger" onClick={handleDeleteDriveClicked}>
									{t('pages.organisations.deleteDrive')}
								</BasicButton>
							</div>
						)}
						{isPro && <OrganisationDrivesAccess organisation={organisation} />}
					</div>
				)}
				{selectedTab === 'access' && (
					<>
						<OrganisationPermissionsManagement organisation={organisation} />
						<label>{t('pages.organisations.toRemoveAllData')}</label>
						<BasicButton variant="danger" className={css['button-small']} onClick={handleDeleteOrganisationClicked}>
							{i18next.t('pages.organisation.deleteOrganisation')}
						</BasicButton>
					</>
				)}
			</div>
			<OrganisationDeleteModal />
			<OrganisationDeleteDriveModal />
		</Document>
	)
}
