import { APIRequest } from '@lynx/client-core/src/api'
import { BillingDetails } from '@lynx/core/src/interfaces'
import { useEffect, useState } from 'react'

export const useBillingDetails = (): { billingDetails: BillingDetails | null; isLoading: boolean } => {
	const [isLoading, setIsLoading] = useState(true)
	const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(null)
	useEffect(() => {
		const getBillingDetails = async (): Promise<void> => {
			setIsLoading(true)
			try {
				const billingDetails = await APIRequest.Billing.getBillingDetails()
				if (billingDetails) {
					setBillingDetails(billingDetails)
				}
			} catch (e) {
				// do nothing
			} finally {
				setIsLoading(false)
			}
		}
		getBillingDetails()
	}, [])

	return { billingDetails, isLoading }
}
