import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext } from 'store/modal'
import { renameGroup } from 'store/organisations'
import css from './RenameGroupModal.module.scss'
import { RenameGroupModalContext } from './RenameGroupModalContext'
import { useTranslation } from 'react-i18next'

export const RenameGroupModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const [context, setContext] = useState<RenameGroupModalContext | null>(null)
	const [groupName, setGroupName] = useState<string>('')

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleRenameClicked = async (): Promise<void> => {
		if (!context || !groupName.length) return
		await dispatchThunk(renameGroup(context.organisationId, context.groupId, groupName))
		dispatch(hideModal())
	}

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== 'RenameGroupModalContextType') {
			return
		}

		setContext(context)
		setGroupName(context.groupName)
	}

	const handleGroupNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setGroupName(e.target.value)
	}

	return (
		<Modal name="RenameGroupModal" title={t('pages.organisations.renameGroup')} onVisible={handleVisible}>
			<div className={css['container']}>
				<div>
					<Input placeholder="group" onChange={handleGroupNameChanged} value={groupName} />
				</div>

				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="blue" onClick={handleRenameClicked}>
						{t('pages.organisations.rename')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
