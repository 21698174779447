import { AddCircleOutlineIcon, BasicButton, BuildingIcon, OrganisationAvatar, ProBadgeIcon } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { setActiveOrganisation } from 'store/organisations'
import css from './AllOrganisations.module.scss'
import { UserOrganisationList } from './UserOrganisationList'

interface AllOrganisationsProps {
	className?: string
}

export const AllOrganisations = ({ className }: AllOrganisationsProps): React.ReactElement | null => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { organisations } = useSelector((state: ApplicationState) => state)

	const handleOrganisationClicked = async (id: number): Promise<void> => {
		dispatch(setActiveOrganisation(id))
	}

	const handleNewOrganisationClicked = (): void => {
		dispatch(showModal({ name: 'OrganisationCreateModal' }))
	}

	const classNames = [css['container']]
	className && classNames.push(className)

	return (
		<div className={classNames.join(' ')}>
			<h4>{t('pages.organisations.allOrganisations')}</h4>
			<UserOrganisationList organisations={organisations.organisations} handleOrganisationClicked={handleOrganisationClicked} />
			<BasicButton icon={AddCircleOutlineIcon} variant="blue" style={{ width: '100%' }} onClick={handleNewOrganisationClicked}>
				{t('pages.organisations.newOrganisation')}
			</BasicButton>
		</div>
	)
}
