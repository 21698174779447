/* eslint-disable */
import { DriveSize } from '@lynx/core/src/interfaces/ObjectStore'
import {
	IOrganisationReport,
	OrganisationAuditPaymentActionSearch,
	OrganisationDriveData,
	OrganisationEmailDomainsInterface,
	OrganisationInviteApiData,
	OrganisationRedux,
	SearchOrganisation
} from '@lynx/core/src/interfaces/Organisation/interfaces'
import { AxiosError } from 'axios'
import { Logger } from '../../modules/Logger'
import { BaseCall } from '../BaseCall'
import {
	APIConfig,
	APIResponse,
	AddedUsers,
	GetEntraIdOrganisationData,
	GetNextCloudOrganisationData,
	GetOauthOrganisationData,
	GetSamlOrganisationData,
	Group,
	Organisation,
	OrganisationUsersWithGravatar
} from '../interfaces'
import { APIQueryParams, PermissionsKeys } from '@lynx/core/src/interfaces'

export class Organisations extends BaseCall {
	public revokeUserPermission = async (organisationId: number, permissionKey: PermissionsKeys): Promise<void> => {
		try {
			await this.client.delete(`/organisations/${organisationId}/permission/${permissionKey}`)
		} catch (e) {
			Logger.error(e)
		}
	}
	public grantUserPermission = async (organisationId: number, permissionKey: PermissionsKeys): Promise<void> => {
		try {
			await this.client.post(`/organisations/${organisationId}/permission/${permissionKey}`)
		} catch (e) {
			Logger.error(e)
		}
	}
	public resetPassword = async (organisationId: number, userId: string): Promise<boolean> => {
		try {
			await this.client.post<APIResponse<boolean>>(`/organisations/${organisationId}/resetPassword`, {
				userId
			})

			return true
		} catch (err) {
			Logger.error(err)
			return false
		}
	}

	public verifyDomain = async (organisationId: number, domainId: number): Promise<APIResponse<boolean | null>> => {
		try {
			const response = await this.client.post<APIResponse<boolean>>(`/organisations/${organisationId}/email-domain/verify`, {
				domainId
			})

			return response.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public getVerificationSecret = async (organisationId: number, domain: string): Promise<APIResponse<{ secret: string } | null> | null> => {
		try {
			const response = await this.client.get<APIResponse<{ secret: string }>>(`/organisations/${organisationId}/email-domain/${domain}/hash`)
			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	/**
	 * ORGANISATIONS
	 */
	public createDrive = async (organisationId: number): Promise<APIResponse | null> => {
		try {
			const response = await this.client.post<APIResponse>(`/organisations/${organisationId}/drive`)
			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public deleteDrive = async (organisationId: number): Promise<APIResponse | null> => {
		try {
			const response = await this.client.delete<APIResponse>(`/organisations/${organisationId}/drive`)
			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public getDriveSize = async (driveId: string): Promise<DriveSize | null> => {
		try {
			const response = await this.client.get<DriveSize>(`/object-store/driveSize/organisation?providerContainerId=${driveId}`)
			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public searchOrganisation = async (params: APIQueryParams): Promise<{ totalCount: number; organisations: SearchOrganisation[] }> => {
		try {
			const response = await this.client.get<{ totalCount: number; organisations: SearchOrganisation[] }>(`/organisations/search`, { params })
			return response.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public getOrganisation = async (id: number): Promise<APIResponse<OrganisationRedux> | null> => {
		try {
			const response = await this.client.get<APIResponse<OrganisationRedux>>(`/organisations/${id}`)
			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public getOrganisations = async (): Promise<APIResponse<Organisation[]>> => {
		try {
			const response = await this.client.get<APIResponse<Organisation[]>>('/organisations')
			return response.data
		} catch (err) {
			return {
				error: true,
				message: JSON.stringify(err),
				handler: 'getOrganisations'
			}
		}
	}

	public uploadOrganisationAvatar = async (orgId: number, pictureFile: File): Promise<{ url: string }> => {
		try {
			const formData = new FormData()
			formData.append('file', pictureFile)

			const response = await this.client.post(`/organisations/${orgId}/upload-avatar`, formData)
			return response.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public getOrganisationsByUser = async (userId?: string): Promise<APIResponse<OrganisationRedux[]> | null> => {
		try {
			const query = userId ? `?userId=${userId}` : ''
			const response = await this.client.get<APIResponse<OrganisationRedux[]>>(`/organisations/all${query}`)
			if (response.status !== 200) {
				return null
			}

			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public createOrganisation = async (
		orgName: string,
		addr: string,
		postCode: string,
		country: string,
		termsAgree: boolean,
		nextCloudDomain?: string,
		nextCloudEmailDomains?: string[],
		nextCloudOrganisationId?: string,
		samlLogin?: string,
		samlIssuer?: string,
		samlCertificate?: string,
		samlEmailDomains?: string[],
		isUCS?: boolean,
		isIserv?: boolean,
		oauthClientId?: string,
		oauthClientSecret?: string,
		oauthAuthorizationUrl?: string,
		oauthTokenUrl?: string,
		oauthUserUrl?: string,
		oauthEmailDomains?: string[],
		isEntraId?: boolean,
		entraIdTenantId?: string,
		entraIdEmailDomains?: string[]
	): Promise<APIResponse<{ id: Organisation['id'] } | null>> => {
		try {
			const response = await this.client.post<APIResponse<{ id: Organisation['id'] } | null>>('/organisations', {
				orgName,
				addr,
				postCode,
				country,
				termsAgree,
				nextCloudDomain,
				nextCloudEmailDomains,
				nextCloudOrganisationId,
				samlLogin,
				samlIssuer,
				samlCertificate,
				samlEmailDomains,
				isUCS,
				isIserv,
				oauthClientId,
				oauthClientSecret,
				oauthAuthorizationUrl,
				oauthTokenUrl,
				oauthUserUrl,
				oauthEmailDomains,
				isEntraId,
				entraIdTenantId,
				entraIdEmailDomains
			})
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public updateOrganisationNextCloud = async (organisationId: number, nextCloudDomain: string): Promise<APIResponse> => {
		try {
			const response = await this.client.post<APIResponse>(`/organisations/nextcloud/${organisationId}`, {
				nextCloudDomain
			})
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public updateOrganisation = async (
		organisationId: number,
		orgName: string,
		addr: string,
		postCode: string,
		country: string,
		nextCloudDomain: string
	): Promise<APIResponse> => {
		const response = await this.client.patch<APIResponse>(`/organisations/${organisationId}`, {
			orgName,
			addr,
			postCode,
			country,
			nextCloudDomain
		})
		return response.data
	}

	public deleteOrganisation = async (organisationId: number): Promise<APIResponse> => {
		try {
			const response = await this.client.delete<APIResponse>(`/organisations/${organisationId}`)
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public updateOrganisationUser = async (
		organisationId: number,
		userId: string,
		payload: { isAdmin?: boolean; isAssignedPro?: boolean }
	): Promise<APIResponse> => {
		try {
			const response = await this.client.patch<APIResponse>(`/organisations/${organisationId}/user/${userId}`, payload)
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public updateAllOrganisationUsers = async (organisationId: number, payload: { groupId: number | null }): Promise<APIResponse> => {
		try {
			const response = await this.client.patch<APIResponse>(`/organisations/${organisationId}/user/pro`, payload)
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public deleteOrganisationUser = async (organisationId: number, userId: string): Promise<APIResponse> => {
		try {
			const response = await this.client.delete<APIResponse>(`/organisations/${organisationId}/user`, { data: { userId } })
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public disableOrganisationUser = async (organisationId: number, userId: string, reason: string): Promise<APIResponse> => {
		try {
			const response = await this.client.post<APIResponse>(`/organisations/${organisationId}/user/disable`, { userId, reason })
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public enableOrganisationUser = async (organisationId: number, userId: string): Promise<APIResponse> => {
		try {
			const response = await this.client.post<APIResponse>(`/organisations/${organisationId}/user/enable`, { userId })
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public inviteOrganisationUser = async (
		organisationId: number,
		email: string,
		groupId: number | null,
		inviteId?: string,
		emailBodyMessage?: string
	): Promise<APIResponse<{ messageId: string | false }>> => {
		const response = await this.client.post<APIResponse<{ messageId: string | false }>>(`/organisations/${organisationId}/invite/`, {
			email,
			inviteId,
			emailBodyMessage,
			groupId
		})
		return response.data
	}

	public updateOrganisationConfigurations = async (
		organisationId: number,
		{ blockedDriveLinkProviders }: { blockedDriveLinkProviders: number[] }
	): Promise<string[]> => {
		try {
			const body = {
				blockedDriveLinkProviders
			}
			const response = await this.client.post<string[]>(`/organisations/${organisationId}/update/configurations`, body)
			return response.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public getInvite = async (organisationId: number, inviteId: string): Promise<OrganisationInviteApiData> => {
		try {
			const response = await this.client.get(`/organisations/${organisationId}/invite/${inviteId}`)
			return response.data.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public acceptOrganisationInvite = async (organisationId: number, inviteId: string): Promise<APIResponse> => {
		try {
			const response = await this.client.post<APIResponse>(`/organisations/${organisationId}/invite/${inviteId}/accept`, {})
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public declineOrganisationInvite = async (organisationId: number, inviteId: string): Promise<APIResponse> => {
		try {
			const response = await this.client.post<APIResponse>(`/organisations/${organisationId}/invite/${inviteId}/decline`, {})
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public getOrganisationUsers = async (organisationId: number): Promise<APIResponse<{ users: OrganisationUsersWithGravatar[] } | null>> => {
		try {
			const response = await this.client.get<APIResponse<{ users: OrganisationUsersWithGravatar[] }>>(`/organisations/${organisationId}/users`)
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	public getOrganisationReport = async (): Promise<APIResponse<IOrganisationReport[] | null>> => {
		try {
			const response = await this.client.get<APIResponse<IOrganisationReport[]>>(`/organisations/report`)
			return response.data
		} catch (err) {
			const error = err as AxiosError
			return error.response?.data as APIResponse
		}
	}

	/**
	 * GOOGLE CLASSROOM
	 */

	public unlinkGoogle = async (organisationId: number): Promise<APIResponse> => {
		const handler = 'organisations:unlinkGoogle'
		try {
			const response = await this.client.post<APIResponse>(`/organisations/${organisationId}/google/unlink`)
			if (response.status === 200) {
				return response.data
			}
			return { error: true, message: 'Could not unlink', handler }
		} catch (e) {
			const error = e as AxiosError
			Logger.error(error)
			return { error: true, message: error.message, handler }
		}
	}

	public getCourses = async (organisationId: number): Promise<APIResponse> => {
		const handler = 'organisations:getCourses'
		try {
			const response = await this.client.get<APIResponse>(`/organisations/${organisationId}/google/courses`)
			if (response.status === 200) {
				return response.data
			}
			return { error: true, handler, message: 'Error getting courses' }
		} catch (e) {
			Logger.error(e)
			return { error: true, handler, message: 'Error getting courses' }
		}
	}

	public getTeachers = async (organisationId: number, courseId: string): Promise<APIResponse> => {
		const handler = 'organisations:getTeachers'
		try {
			const response = await this.client.get<APIResponse>(`/organisations/${organisationId}/google/${courseId}/teachers`)
			if (response.status === 200) {
				return response.data
			}
			return { error: true, handler, message: 'Error getting teachers' }
		} catch (e) {
			Logger.error(e)
			return { error: true, handler, message: 'Error getting teachers' }
		}
	}

	public getStudents = async (organisationId: number, courseId: string): Promise<APIResponse> => {
		const handler = 'organisations:getStudents'
		try {
			const response = await this.client.get<APIResponse>(`/organisations/${organisationId}/google/${courseId}/students`)
			if (response.status === 200) {
				return response.data
			}
			return { error: true, handler, message: 'Error getting Students' }
		} catch (e) {
			Logger.error(e)
			return { error: true, handler, message: 'Error getting Students' }
		}
	}

	public getTopics = async (organisationId: number, courseId: string): Promise<APIResponse> => {
		const handler = 'organisations:getTopics'
		try {
			const response = await this.client.get<APIResponse>(`/organisations/${organisationId}/google/${courseId}/topics`)
			if (response.status === 200) {
				return response.data
			}
			return { error: true, handler, message: 'Error getting Topics' }
		} catch (e) {
			Logger.error(e)
			return { error: true, handler, message: 'Error getting Topics' }
		}
	}

	/**
	 * GROUPS
	 */
	public getAllUsersGroups = async (): Promise<APIResponse<Organisation[]> | null> => {
		try {
			const response = await this.client.get<APIResponse<Organisation[]>>(`/organisations/groups`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public getGroups = async (organisationId: number): Promise<APIResponse<Group[]>> => {
		try {
			const response = await this.client.get<APIResponse<Group[]>>(`/organisations/${organisationId}/groups`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: 'Error getting groups',
				handler: 'organisations:getGroups'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Error getting groups',
				handler: 'organisations:getGroups'
			}
		}
	}

	public addGroup = async (organisationId: number, groupName: string): Promise<APIResponse<Group> | null> => {
		try {
			const response = await this.client.post<APIResponse<Group>>(`/organisations/${organisationId}/groups/`, { groupName })

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public deleteGroup = async (organisationId: number, groupId: string): Promise<APIResponse<Group> | null> => {
		try {
			const response = await this.client.delete<APIResponse<Group>>(`/organisations/${organisationId}/groups/${groupId}`)
			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public renameGroup = async (organisationId: number, groupId: string, groupName: string): Promise<APIResponse<Group> | null> => {
		try {
			const response = await this.client.post<APIResponse<Group>>(`/organisations/${organisationId}/groups/${groupId}/rename`, {
				groupName
			})
			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public addUserToGroup = async (
		organisationId: number,
		groupId: string,
		userId: string,
		isEnabled: boolean,
		isAdmin: boolean,
		isReadonly: boolean
	): Promise<APIResponse<Group> | null> => {
		try {
			const response = await this.client.post<APIResponse<Group>>(`/organisations/${organisationId}/groups/${groupId}/user`, {
				userId,
				isEnabled,
				isAdmin,
				isReadonly
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public updateUserInGroup = async (
		organisationId: number,
		groupId: string,
		userId: string,
		isEnabled: boolean,
		isAdmin: boolean,
		isReadonly: boolean
	): Promise<APIResponse<Group> | null> => {
		try {
			const response = await this.client.patch<APIResponse<Group>>(`/organisations/${organisationId}/groups/${groupId}/user`, {
				userId,
				isEnabled,
				isAdmin,
				isReadonly
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public deleteUserInGroup = async (organisationId: number, groupId: string, userId: string): Promise<APIResponse<Group> | null> => {
		try {
			const response = await this.client.delete<APIResponse<Group>>(`/organisations/${organisationId}/groups/${groupId}/user`, {
				data: { userId }
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public updateGroupName = async (organisationId: number, groupId: string, groupName: string): Promise<APIResponse | null> => {
		try {
			const response = await this.client.patch<APIResponse>(`/organisations/${organisationId}/groups/${groupId}`, {
				groupName
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	/**
	 * CSV USER CREATION
	 */

	public getEmailDomains = async (organisationId: number): Promise<APIResponse<OrganisationEmailDomainsInterface[]>> => {
		try {
			const response = await this.client.get<APIResponse<OrganisationEmailDomainsInterface[]>>(`/organisations/${organisationId}/email-domains`, {
				data: { organisationId }
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: `${response.status}:${response.statusText}`,
				handler: 'getEmailDomains'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Could not get email domains',
				handler: 'getEmailDomains'
			}
		}
	}

	public addEmailDomain = async (
		organisationId: number,
		domain: string,
		isNextCloud: boolean,
		isSAML: boolean,
		isOauth: boolean,
		isEntraId: boolean
	): Promise<APIResponse<boolean>> => {
		try {
			const response = await this.client.post<APIResponse<boolean>>(`/organisations/${organisationId}/email-domains`, {
				organisationId,
				domain,
				isNextCloud,
				isSAML,
				isOauth,
				isEntraId
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: `${response.status}:${response.statusText}`,
				handler: 'addEmailDomain'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Could not add email domain',
				handler: 'addEmailDomain'
			}
		}
	}

	public removeEmailDomain = async (organisationId: number, domainId: number): Promise<APIResponse<boolean>> => {
		try {
			const response = await this.client.delete<APIResponse<boolean>>(`/organisations/${organisationId}/email-domain`, {
				data: { organisationId, domainId }
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: `${response.status}:${response.statusText}`,
				handler: 'removeEmailDomain'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Could not remove email domain',
				handler: 'removeEmailDomain'
			}
		}
	}

	public addCsvUsers = async (organisationId: number, file: File): Promise<APIResponse<AddedUsers>> => {
		try {
			const data = new FormData()
			data.append('file', file)
			data.append('organisationId', String(organisationId))

			const response = await this.client.post<APIResponse<AddedUsers>>(`/organisations/${organisationId}/email-domains/csv`, data)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: `${response.status}:${response.statusText}`,
				handler: 'addCsvUsers'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Could not add users',
				handler: 'addCsvUsers'
			}
		}
	}

	public emailCsvUsers = async (organisationId: number, file: File): Promise<APIResponse<{ message: string }>> => {
		try {
			const data = new FormData()
			data.append('file', file)
			data.append('organisationId', String(organisationId))

			const response = await this.client.post<APIResponse<{ message: string }>>(`/organisations/${organisationId}/email-domains/csv/email`, data)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: `${response.status}:${response.statusText}`,
				handler: 'emailCsvUsers'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Could not email users',
				handler: 'emailCsvUsers'
			}
		}
	}

	public addNextCloudSupport = async (organisationId: number, emailDomainId: number, nextCloudLoginSupport: boolean): Promise<APIResponse<Group>> => {
		try {
			const response = await this.client.post<APIResponse<Group>>(`/organisations/${organisationId}/email-domains/nextcloud`, {
				emailDomainId,
				nextCloudLoginSupport
			})

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return {
				error: true,
				message: `${response.status}:${response.statusText}`,
				handler: 'addNextCloudSupport'
			}
		} catch (e) {
			Logger.error(e)
			return {
				error: true,
				message: 'Could not add nextcloud support',
				handler: 'addNextCloudSupport'
			}
		}
	}
	public nextCloudOrganisationConfirmAccess = async (id: string): Promise<APIResponse<{ nextCloudTemporaryAccessId: string }> | null> => {
		const response = await this.client.post(`/organisations/nextCloudOrganisation/confirmAccess`, {
			id
		})

		if (response.status === 200) {
			return response.data
		}

		Logger.error(`${response.status}:${response.statusText}`)
		return null
	}
	public nextCloudOrganisationInitAuth = async (domain: string): Promise<APIResponse<{ loginUrl: string; nextCloudTemporaryAccessId: string }> | null> => {
		const response = await this.client.post(`/organisations/nextCloudOrganisation/initAuth`, {
			domain
		})

		if (response.status === 200) {
			return response.data
		}

		Logger.error(`${response.status}:${response.statusText}`)
		return null
	}
	public nextCloudOrganisationGetInitialDetails = async (id: string): Promise<APIResponse<{ address: string; organisationName: string }> | null> => {
		const response = await this.client.get(`/organisations/nextCloudOrganisation/${id}`)

		if (response.status === 200) {
			return response.data
		}

		Logger.error(`${response.status}:${response.statusText}`)
		return null
	}

	public getNextCloudOrganisationDetails = async (id: number): Promise<APIResponse<GetNextCloudOrganisationData> | null> => {
		const response = await this.client.get(`/organisations/${id}/nextCloudOrganisation`)

		if (response.status === 200) {
			return response.data
		}

		Logger.error(`${response.status}:${response.statusText}`)
		return null
	}

	public bulkCreateAccounts = async (
		id: number,
		accountsData: { email: string; userName: string; isAdmin: boolean }[]
	): Promise<APIResponse<GetNextCloudOrganisationData> | null> => {
		const response = await this.client.post(`/organisations/${id}/bulkCreateAccounts`, {
			accountsData
		})

		if (response.status === 200) {
			return response.data
		}

		Logger.error(`${response.status}:${response.statusText}`)
		return null
	}

	public getOauthOrganisationDetails = async (id: number): Promise<APIResponse<GetOauthOrganisationData> | null> => {
		try {
			const response = await this.client.get(`/organisations/${id}/oauthOrganisation`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public updateOauthSettings = async (
		organisationId: number,
		authorizationUrl: string,
		tokenUrl: string,
		userUrl: string,
		clientId: string,
		clientSecret: string
	): Promise<boolean> => {
		try {
			const response = await this.client.post(`/organisations/${organisationId}/oauth/updateSettings`, {
				authorizationUrl,
				tokenUrl,
				userUrl,
				clientId,
				clientSecret
			})

			if (response.status === 200) {
				return true
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public getSamlOrganisationDetails = async (id: number): Promise<APIResponse<GetSamlOrganisationData> | null> => {
		try {
			const response = await this.client.get(`/organisations/${id}/samlOrganisation`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public getEntraIdOrganisationDetails = async (id: number): Promise<APIResponse<GetEntraIdOrganisationData> | null> => {
		try {
			const response = await this.client.get(`/organisations/${id}/entraIdOrganisation`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public updateEntraIdSettings = async (organisationId: number, tenantId: string): Promise<boolean> => {
		try {
			const response = await this.client.post(`/organisations/${organisationId}/entraid/updateSettings`, {
				tenantId
			})

			if (response.status === 200) {
				return true
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public updateSamlSettings = async (organisationId: number, entrypoint: string, issuer: string, cert?: string): Promise<boolean> => {
		try {
			const response = await this.client.post(`/organisations/${organisationId}/saml/updateSettings`, {
				entrypoint,
				issuer,
				cert
			})

			if (response.status === 200) {
				return true
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public getOrganisationDrives = async (organisationId: number): Promise<APIResponse<{ drives: OrganisationDriveData[] }> | null> => {
		try {
			const response = await this.client.get(`/organisations/${organisationId}/drive`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}
	public updateUserStorageLimit = async (organisationId: number, storageLimit: number | null, previousStorageLimit: number): Promise<boolean> => {
		try {
			const response = await this.client.post(`/organisations/${organisationId}/storageLimit`, { storageLimit, previousStorageLimit })

			return response.status === 200
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public updateUserLicensesLimit = async (organisationId: number, userLicensesLimit: number): Promise<boolean> => {
		try {
			const response = await this.client.post(`/organisations/${organisationId}/userLicensesLimit`, { userLicensesLimit })

			return response.status === 200
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public getOrganisationAuditTrail = async (
		searchParams: APIQueryParams
	): Promise<{ totalCount: number; audits: OrganisationAuditPaymentActionSearch[] }> => {
		try {
			const response = await this.client.get(`/organisations/auditTrailSearch`, { params: searchParams })

			return response.data
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
