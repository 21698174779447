import { APIRequest } from '@lynx/client-core/src/api'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { AccountProfile } from '@lynx/core/src/interfaces'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { useEffect, useState } from 'react'
import { UserOrganisationList } from 'pages/Organisations/components/UserOrganisationList'
import css from './UserDetailsModal.module.scss'
export const UserDetailsModal = ({ user, onClose }: { user: AccountProfile; onClose: () => void }): React.ReactElement => {
	const [organisationsData, setOrganisationsData] = useState<OrganisationRedux<'parsed'>[]>([])

	const fetchDetails = async (): Promise<void> => {
		const orgData = await APIRequest.Organisations.getOrganisationsByUser(user.userId)
		if (!orgData?.data) return
		setOrganisationsData(orgData?.data)
	}

	useEffect(() => {
		fetchDetails()
	}, [])

	return (
		<ModalBase name={'User Details'} title={'User Details'} uncontrolled onHidden={onClose}>
			<div className={css.container}>
				<div>
					<div>Providers</div>
					<div>{user.providers}</div>
				</div>
				<div>
					<div>Organisations</div>
					<UserOrganisationList organisations={organisationsData} />
				</div>
			</div>
		</ModalBase>
	)
}
