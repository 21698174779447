import { OrganisationAvatar, ProBadgeIcon } from '@lynx/client-core/src/components'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import css from './UserOrganisationList.module.scss'

interface Props {
	organisations: OrganisationRedux[]
	handleOrganisationClicked?: (id: number) => void
}

export const UserOrganisationList = ({ organisations, handleOrganisationClicked }: Props): React.ReactElement => {
	const organisationElements = organisations.map(({ id, organisationName, isPro, avatarUrl }, key) => (
		<li
			key={id}
			onClick={(): void => {
				handleOrganisationClicked?.(id)
			}}
		>
			<div className={css['icons']}>
				{avatarUrl && <OrganisationAvatar avatarUrl={avatarUrl} />}
				{isPro && <ProBadgeIcon className={css['icons__pro']} />}
			</div>
			<div>
				<div>{organisationName}</div>
				<div className={css['id']}>ID: {id}</div>
			</div>
		</li>
	))
	return <ul className={css.list}>{organisationElements}</ul>
}
