import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteOrganisation, setActiveOrganisation } from 'store/organisations'
import css from './OrganisationDeleteModal.module.scss'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { useNavigate } from 'react-router-dom'

export const OrganisationDeleteModal = (): React.ReactElement | null => {
	const organisation = useCurrentOrganisation()
	const { organisations } = useSelector((state: ApplicationState) => state)
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()

	if (!organisation) return null

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!organisation) return
		await dispatchThunk(deleteOrganisation(organisation.id))
		const anotherOrg = organisations.organisations.find((org) => org.id !== organisation.id)
		if (anotherOrg) {
			dispatch(setActiveOrganisation(anotherOrg.id))
			navigate(`/organisations/${anotherOrg.id}`)
		} else navigate('/organisations')

		dispatch(hideModal())
	}

	return (
		<Modal name="OrganisationDeleteModal" title="Delete Organisation" variant="danger">
			<div className={css['container']}>
				<div>
					{organisation.organisationName} {t('pages.organisations.organisationWillBeDeleted')}
				</div>
				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleDeleteClicked}>
						{t('pages.organisations.delete')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
