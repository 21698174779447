import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalContext } from 'store/modal'
import css from './OrganisationEntryModal.module.scss'
import { OrganisationEntryModalContextType } from './OrganisationEntryModalContextType'
import { OrganisationEntry } from './components'

export const OrganisationEntryModal = (): React.ReactElement | null => {
	const [organisation, setOrganisation] = useState<OrganisationRedux | null>(null)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleCloseClicked = async (): Promise<void> => {
		dispatch(hideModal())
	}

	const handleModalVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== OrganisationEntryModalContextType) {
			return
		}

		const organisationResponse = await APIRequest.Organisations.getOrganisation(context.id)
		if (!organisationResponse?.data) return

		setOrganisation(organisationResponse.data)
	}

	const handleModalHidden = (): void => {
		setOrganisation(null)
	}

	return (
		<Modal
			name="OrganisationEntryModal"
			title="Organisation"
			onVisible={handleModalVisible}
			onHidden={handleModalHidden}
			innerContainerClasses={css.innerModalContainer}
		>
			<div className={css['container']}>
				{organisation && <OrganisationEntry organisation={organisation} />}
				<div className={css['buttons']}>
					<BasicButton variant="blue" onClick={handleCloseClicked}>
						{t('pages.organisations.close')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
