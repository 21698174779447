import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useOrganisationDriveSize } from 'pages/Organisations/hooks/useOrganisationDriveSize'
import css from './DriveUsage.module.scss'
import { CloudOrganisationIcon } from '@lynx/client-core/src/components'

const getGigabytesFromBytes = (bytes: number): number => bytes / 1024 / 1024 / 1024

export const DriveUsage = (): React.ReactElement | null => {
	const organisation = useCurrentOrganisation()
	const { driveSize, isLoading } = useOrganisationDriveSize(organisation?.driveId)

	if (isLoading || !driveSize) return null

	const { usedBytes, totalBytes } = driveSize
	const usagePercentage = (usedBytes / totalBytes) * 100
	const usedGigabytes = getGigabytesFromBytes(usedBytes)
	const totalGigabytes = getGigabytesFromBytes(totalBytes)

	return (
		<div className={css['container']}>
			<div className={css['container__graph-container']}>
				<CloudOrganisationIcon />
				<div className={css['container__graph-container__graph']}>
					<div style={{ width: `${usagePercentage > 100 ? 100 : usagePercentage}%` }} className={css['usageIndicator']} />
					<div>
						{Number(usedGigabytes.toFixed(2))}GB of {totalGigabytes}GB
					</div>
				</div>
			</div>
		</div>
	)
}
