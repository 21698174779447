import { BasicButton, OrganisationInfo } from '@lynx/client-core/src/components'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import css from './ActiveOrganisation.module.scss'
import { DriveUsage } from './components'

interface ActiveOrganisationProps {
	className?: string
}

export const ActiveOrganisation = ({ className }: ActiveOrganisationProps): React.ReactElement | null => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const organisation = useCurrentOrganisation()
	if (!organisation) return null

	const { organisationName, countryName } = organisation

	const handleUpgradeClicked = (): void => {
		navigate(`/contact-us?organisationId=${organisation.id}`)
	}

	const classNames = [css['container']]
	className && classNames.push(className)

	const { isPro, isOwner, id } = organisation

	return (
		<div className={classNames.join(' ')}>
			<OrganisationInfo organisationCountry={countryName} organisationName={organisationName} organisationId={id} isPro={isPro} />
			<DriveUsage />
			{(!isPro || isOwner) && (
				<div className={css['buttons']}>
					{isOwner && (
						<BasicButton to={`/organisations/${id}/edit`} className={css.editOrgBtn}>
							Edit Organisation
						</BasicButton>
					)}
					{!isPro && (
						<BasicButton variant="pro" onClick={handleUpgradeClicked}>
							{t('pages.organisations.upgrade')}
						</BasicButton>
					)}
				</div>
			)}
		</div>
	)
}
