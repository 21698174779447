const prefix = 'organisation'
export const OrganisationActivitiesPermission = `${prefix}Activities` as const
export const OrganisationAiPermission = `${prefix}Ai` as const
export const OrganisationConversionsPermissions = `${prefix}Conversions` as const
export const OrganisationDrivePermission = `${prefix}Drive` as const
export const OrganisationSharingPermission = `${prefix}Sharing` as const

export const organisationPermissions = [
	OrganisationActivitiesPermission,
	OrganisationAiPermission,
	OrganisationConversionsPermissions,
	OrganisationDrivePermission,
	OrganisationSharingPermission
] as const

export const organisationPermissionDefaultValue: {
	isGranted: boolean
	organisationId?: number
	organisationName?: string
	organisationCountry?: string
	organisationAvatarUrl?: string
} = {
	isGranted: true
}

export const organisationPermissionsData: {
	[key in (typeof organisationPermissions)[number]]: {
		description: string
		label: string
		permissionId: number
		defaultValue: typeof organisationPermissionDefaultValue
	}
} = {
	[OrganisationActivitiesPermission]: {
		description: 'Full Access - Activities',
		label: 'Activities',
		permissionId: 1000,
		defaultValue: organisationPermissionDefaultValue
	},
	[OrganisationAiPermission]: {
		description: 'Full Access - AI Features',
		label: 'AI',
		permissionId: 1001,
		defaultValue: organisationPermissionDefaultValue
	},
	[OrganisationConversionsPermissions]: {
		description: 'Full Access - Conversions',
		label: 'Conversions',
		permissionId: 1002,
		defaultValue: organisationPermissionDefaultValue
	},
	[OrganisationDrivePermission]: {
		description: 'Full Access - Drive',
		label: 'Drive',
		permissionId: 1003,
		defaultValue: organisationPermissionDefaultValue
	},
	[OrganisationSharingPermission]: {
		description: 'Full Access - Sharing',
		label: 'Sharing',
		permissionId: 1004,
		defaultValue: organisationPermissionDefaultValue
	}
}
